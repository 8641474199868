
.landing-page{
    @include media-breakpoint-down(lg){
        .display-2{
            font-size: 110px;
        }
        .display-3{
            font-size: 85px;
        }
    }
    @include media-breakpoint-down(md){
        .display-2{
            font-size: 60px;
        }
        .display-3{
            font-size: 50px;
        }
    }
    @include media-breakpoint-down(sm){
        .display-2{
            font-size: 52px;
        }
        .display-3{
            font-size: 40px;
        }
    }
}
.hero-landing{
    background-image: url('../images/hero-landing.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
    @include media-breakpoint-down(xxl){
        padding-top: 84px;
    }
    @include media-breakpoint-down(md) {        
        padding-top: 68px;
    }
}
.hero-landing-contents{
    --#{$prefix}landing-contents-padding: 160px 0 230px 0;
    --#{$prefix}landing-contents-max-width: 1250px;
    --#{$prefix}hero-btns-margin-top: 56px;
    padding: var(--#{$prefix}landing-contents-padding);
    max-width: var(--#{$prefix}landing-contents-max-width);
    margin: 0 auto;
    .button{
        margin-top: var(--#{$prefix}hero-btns-margin-top);
    }
    @include media-breakpoint-down(xxl){
        --#{$prefix}landing-contents-padding: 130px 0 180px 0;
        --#{$prefix}landing-contents-max-width: 1000px;
        --#{$prefix}hero-btns-margin-top: 45px;
    }
    @include media-breakpoint-down(md) {        
        --#{$prefix}hero-btns-margin-top: 36px;
    }
}

.demo-image {
    position: relative;
    top: 0;
    transition: all 400ms ease-in-out;
    padding: 40px 16px 40px 16px;
    
    &:hover{
        top: -12px;
    }
}

.landing-inner-bg {
    --#{$prefix}landing-inner-bg: var(--#{$prefix}secondary);
    background-color: var(--#{$prefix}landing-inner-bg); 
    border-radius: 8px;
    img{
        border-radius: 8px;
    }
}

.demos .demo-title {
    --#{$prefix}demo-title-color: var(--#{$prefix}primary);
    color: var(--#{$prefix}demo-title-color);
    font-weight: 800;
    font-size: 22px;
    line-height: 1;
    position: relative;
    top: 22px;
    transition: all 400ms ease-in-out;
}

.dots {
    position: absolute;
    top: 8px;
}